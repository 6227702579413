<template>
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 250 250" xml:space="preserve" :width="size + 'px'" :fill="color">
        <g xmlns="http://www.w3.org/2000/svg" id="Layer_8">
            <g>
                <g>
                    <g>
                        <g>
                            <rect x="115.4" y="117.9" class="st0" width="23.3" height="60.6"/>
                        </g>
                    </g>
                </g>
            </g>
            <g>
                <g>
                    <circle class="st1" cx="125" cy="125" r="124.5"/>
                </g>
                <g>
                    <path class="st2" d="M90.8,104.2c-1-1.2-1.9-1.9-2.9-2.2c-0.8-0.3-1.5-0.4-2.3-0.4c-2,0-4,0.9-5.4,2.5c-1.8,2.1-2.2,5.3-0.8,7.8     c0.8,1.6,2,2.6,3.5,3.2c1.1,0.4,2.3,0.5,3.6,0.5c2.5,0.1,5.3,0,8.9-0.5c0.7-0.1,1.3-0.2,2-0.2c-0.8-1.5-1.5-3-2.3-4.4     C93.5,107.7,92.9,106.6,90.8,104.2z"/>
                    <path class="st2" d="M120,115.4c1.5-0.2,2.6-0.5,3.5-1.2c2.3-1.7,3.3-4.7,2.5-7.5c-0.7-2.7-3.1-4.8-6-5.1     c-1.8-0.2-3.3,0.2-4.6,1.1c-0.9,0.7-1.7,1.6-2.4,2.7c-1.5,2.1-2.9,4.4-4.6,7.6c-0.3,0.6-0.6,1.2-0.9,1.8c1.6,0.2,3.3,0.4,5,0.6     C115.3,115.7,117.7,115.7,120,115.4z"/>
                    <path class="st2" d="M62.8,86.1v24.2h9.1c-0.5-3.7,0.6-7.5,3.1-10.5c3.6-4.2,9.4-5.8,14.6-4c2.1,0.7,4,2.1,5.8,4.2     c0.8,0.9,1.4,1.7,1.9,2.4V81.2H67.7C65,81.2,62.8,83.4,62.8,86.1z"/>
                    <path class="st2" d="M77.3,140.9c-1,0-2.1-0.5-2.8-1.4c-1.1-1.5-0.8-3.7,0.7-4.8l17.2-12.9c-1.6,0.1-3,0.2-4.4,0.2     c-0.6,0-1.3,0-2,0c-1.8-0.1-3.7-0.2-5.7-0.9c-2-0.7-3.6-1.8-5-3.3H62.8v46.1c0,2.7,2.2,4.9,4.9,4.9h29.6v-42l-17.9,13.4     C78.8,140.7,78.1,140.9,77.3,140.9z"/>
                    <path class="st2" d="M127.7,119.4c-1.8,1.4-3.9,2.1-6.7,2.4c-2.8,0.3-5.9,0.3-9.2,0l16.2,15.9c1.4,1.3,1.4,3.5,0,4.9     c-0.7,0.7-1.6,1-2.5,1s-1.7-0.3-2.4-1L108.2,128v40.8h74.1c2.7,0,4.9-2.2,4.9-4.9v-45.2h-58.8     C128.2,118.9,127.9,119.2,127.7,119.4z M137.2,133.1H168c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1h-30.8     c-1.7,0-3.1-1.4-3.1-3.1C134.1,134.4,135.5,133.1,137.2,133.1z M137.2,147.3h19.9c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1     h-19.9c-1.7,0-3.1-1.4-3.1-3.1C134.1,148.7,135.5,147.3,137.2,147.3z"/>
                    <path class="st2" d="M182.3,81.2h-74.1v20.3c1-1.4,2.2-2.8,3.8-4c2.6-1.9,5.6-2.7,9-2.3c5.5,0.6,10.1,4.5,11.5,9.8     c0.4,1.5,0.5,3,0.4,4.5h54.3V86.1C187.2,83.4,185,81.2,182.3,81.2z"/>
                </g>
            </g>
        </g>
    </svg>
</template>
<script>
export default {
    name: 'GiftCards',
    props:{
    size: {
        type:Number,
        default: 20,
        },
    color:{
        type: String,
        default: ''
    }
    }
}
</script>
<style scoped>
    .st0{display:none;opacity:0.25;fill:#002B38;enable-background:new    ;}
	.st1{fill:#EFFFE1;}
	.st2{fill:#85B561;}
</style>